import React, { useState } from 'react'
import { MapSVG } from '../../assets/mapsvg'
import { Backdrop } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import { T } from '../../typo'
import { colors, s, alpha, globals } from '../../style'
import { ButtonMain } from '../../buttons'

const Map = ({ data, theme, language }) => {
  const [selected, setSelected] = useState(null)
  const {
    slug_section,
    descriptionObject,
    subtitleObject,
    titleObjectList,
  } = data
  const dataQuery = useStaticQuery(graphql`
    {
      countries: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/countries/" } }
      ) {
        edges {
          node {
            frontmatter {
              shortcut
              language
              name
              origin_name
              descriptionList {
                text
              }
              featuresList {
                image {
                  name
                  publicURL
                }
                text
                value
                unit
              }
            }
          }
        }
      }
    }
  `)

  // console.log(data)

  const countries = dataQuery.countries.edges
    .map(({ node }) => node.frontmatter)
    .reduce((ac, a) => ({ ...ac, [a.shortcut]: a }), {})

  selected && console.log(countries[selected])

  return (
    <section id={slug_section} css={sSection}>
      {/* <div css={sTextContainer}>selected: {selected}</div> */}
      <div css={sMap(theme)}>
        <MapSVG selected={selected} setSelected={setSelected} theme={theme} />
      </div>
      <div css={sIntro(theme)}>
        <T
          m={48}
          d={96}
          sm={48}
          lg={72}
          md={64}
          variant={titleObjectList.variant}
          extraCss={{ display: 'flex', flexDirection: 'column' }}>
          {titleObjectList.texts.map((text, id) => (
            <span
              css={[
                sTextGardient(theme).title,
                { fontWeight: text.fontWeight },
                text.uppercase && { textTransform: 'uppercase' },
              ]}
              key={id}>
              {text.text}
            </span>
          ))}
        </T>
        {subtitleObject && (
          <T
            xs={20}
            sm={24}
            md={28}
            lg={32}
            xl={32}
            upper={subtitleObject.uppercase}
            variant={subtitleObject.variant}
            extraCss={[
              sTextGardient(theme).subtitle,
              {
                letterSpacing: '0.06em',
                lineHeight: '1.2em',
                fontWeight: subtitleObject.fontWeight,
                color: subtitleObject.color,
                [s.sm_down]: {
                  width: '100%',
                  paddingTop: '1rem',
                },
                [s.md]: {
                  width: '75%',
                  paddingTop: '2rem',
                },
              },
            ]}>
            {subtitleObject.text}
          </T>
        )}
        {descriptionObject && (
          <T
            m={14}
            t={16}
            d={18}
            bold
            variant={descriptionObject.variant}
            extraCss={[
              sTextGardient(theme).description,
              {
                fontWeight: descriptionObject.fontWeight,
                [s.sm_down]: {
                  width: '100%',
                  padding: '1rem 0rem',
                },
                [s.md]: {
                  width: '50%',
                  padding: '2rem 0',
                },
              },
            ]}>
            {descriptionObject.text}
          </T>
        )}
      </div>
      <Backdrop
        open={Boolean(selected)}
        onClick={() => setSelected(null)}
        css={sBackdrop(theme)}>
        <div css={sTextContainer(theme)}>
          <div css={{ marginBottom: '1.5rem' }}>
            <T d={48} m={32} bold extraCss={{ color: colors[theme].main }}>
              {selected && countries[selected]?.name}
            </T>
            <T d={20} m={16} o={0.3} bold upper spacious>
              {selected && countries[selected]?.origin_name}
            </T>
          </div>
          {selected &&
            countries[selected]?.descriptionList.map(({ text }, id) => (
              <T
                key={id}
                m={14}
                d={14}
                o={0.3}
                extraCss={{
                  [s.sm_down]: { marginBottom: '1.5rem' },
                  [s.md]: { marginBottom: '2rem', maxWidth: '50%' },
                }}>
                {text}
              </T>
            ))}
          {selected &&
            countries[selected]?.featuresList?.map((feature, id) => (
              <div
                key={id}
                css={[sCountryFeature, { color: colors[theme].main }]}>
                <div
                  className="countryFeature-icon"
                  css={{ backgroundColor: colors[theme].main }}>
                  {feature.image && (
                    <img
                      src={feature.image?.publicURL}
                      alt={feature.image?.name}
                    />
                  )}
                </div>
                <div>
                  <span className="countryFeature-text">{feature.text}:</span>
                  <span className="countryFeature-value"> {feature.value}</span>
                  <span className="countryFeature-unit"> {feature.unit}</span>
                </div>
              </div>
            ))}
          {countries[selected]?.shortcut && (
            <ButtonMain
              theme={theme}
              language={countries[selected]?.language}
              button={{ link_page: countries[selected]?.shortcut }}
              extraCss={sButton}>
              {countries[selected]?.name}
            </ButtonMain>
          )}
        </div>
      </Backdrop>
    </section>
  )
}

const sButton = {
  margin: '3rem 0 2rem',
}

const sSection = {
  position: 'relative',
  [s.sm_down]: { top: -globals.menu.height.mobile },
  [s.md]: { top: -globals.menu.height.desktop },
  left: 0,
  width: '100vw',
  height: '100vh',
  // backgroundColor: 'rgba(0,0,255, 0.1)',
}

const sMap = (theme) => ({
  zIndex: 4,
  svg: {
    position: 'absolute',
    // backgroundColor: 'rgba(0,0,255, 0.1)',
    [s.md]: {
      top: 0,
      right: 0,
      width: 'auto',
      maxWidth: '100vw',
      height: '100vh',
    },

    [s.sm_down]: {
      width: '100%',
      maxWidth: '100vw',
      bottom: globals.menu.height.mobile / 2,
      right: 0,
      height: 'auto',
    },
  },
})

const sIntro = (theme) => ({
  position: 'absolute',
  zIndex: 5,
  pointerEvents: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  width: '50%',
  height: '100%',
  padding: '2rem',

  [s.sm_down]: {
    height: 'max-content',
    width: '100%',
    top: 0,
    left: 0,
    paddingTop: globals.menu.height.mobile * 1.5,
    paddingBottom: globals.menu.height.mobile,
    background: `linear-gradient(${colors[theme].background[0].concat(
      alpha[100]
    )}, ${colors[theme].background[0].concat(alpha[100])} 50%, ${colors[
      theme
    ].background[1].concat(alpha[0])})`,
  },
  // [s.sm_only]: {
  //   paddingTop: globals.menu.height.mobile * 1.5,
  // },
  // [s.ss]: {
  //   paddingTop: globals.menu.height.mobile * 1.5,
  // },
  [s.md]: {
    height: '100%',
    width: '50%',
    top: 0,
    left: 0,
    background: `linear-gradient(90deg, ${colors[theme].background[0].concat(
      alpha[100]
    )}, ${colors[theme].background[0].concat(alpha[100])} 30%, ${colors[
      theme
    ].background[1].concat(alpha[0])})`,
  },
})

const sTextGardient = (theme) => ({
  title: {
    background: `linear-gradient(45deg, ${colors[theme].mainLighter.concat(
      alpha[100]
    )}, ${colors[theme].mainDarker.concat(alpha[100])})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  subtitle: {
    background: `linear-gradient(45deg, ${colors[theme].mainLighter.concat(
      alpha[60]
    )}, ${colors[theme].mainDarker.concat(alpha[100])})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  description: {
    background: `linear-gradient(45deg, ${colors[theme].text.concat(
      alpha[30]
    )}, ${colors[theme].text.concat(alpha[16])})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
})

const sBackdrop = (theme) => ({
  zIndex: 6,
  cursor: 'pointer',
  background: `linear-gradient(90deg, ${colors[theme].background[0].concat(
    alpha[100]
  )} 30% , ${colors[theme].background[1].concat(alpha[60])})`,
  [s.sm_down]: {
    paddingTop: globals.menu.height.mobile,
    background: `linear-gradient(${colors[theme].background[0].concat(
      alpha[100]
    )} 30% , ${colors[theme].background[1].concat(alpha[60])})`,
  },
})

const sTextContainer = (theme) => ({
  // backgroundColor: 'rgba(255,0,0,0.2)',
  width: '100%',
  height: '100%',
  maxWidth: globals.maxWidth,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [s.sm_down]: {
    justifyContent: 'flex-start',
    paddingTop: globals.menu.height.mobile,
  },
  [s.lg_1280]: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  [s.md_down]: {
    padding: globals.spacing.inside.mobile,
  },
  overflow: 'scroll',
})

const sCountryFeature = {
  display: 'flex',
  marginTop: '1rem',
  alignItems: 'center',
  '.countryFeature-icon': {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    borderRadius: 12,
    marginRight: '1rem',
    img: {
      width: 12,
      height: 12,
      filter: 'brightness(0) grayscale(1) invert(1)',
    },
  },
  '.countryFeature-text': {
    fontWeight: 400,
    fontSize: 12,
    opacity: 0.6,
  },
  '.countryFeature-value': {
    fontWeight: 700,
    fontSize: 16,
    marginRight: '0.25rem',
  },
  '.countryFeature-unit': {
    fontWeight: 700,
    fontSize: 16,
    opacity: 0.3,
  },
}

export default Map
